/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}

// Project Imports
import env from '@js/utils/env';
import utils from '@js/utils/utils';
import Signal from '@js/classes/Signal';
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/VhCalc';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import NavActions from '@js/modules/NavActions';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import Forms from '@js/modules/Forms';
import ScrollTo from '@js/modules/ScrollTo';

// JS imports
import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';

window.SYSTEM =
    window.SYSTEM ||
    new (function SYSTEM() {
        // ENV
        this.env = env;

        // Utilities
        this.utils = utils;

        // Classes
        this.classes = {
            Signal: Signal
        };

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            VhCalc: VhCalc
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            Forms: Forms,
            NavActions: NavActions,
            ScrollTo: ScrollTo
        };

        // Check to run animations
        if (!this.env.reducedMotion) {
            this.modules.Appear.start();
            this.modules.AppearParent.start();
        }

        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            /**
             * Lazy load
             */
            let lazyLoadInstance = new LazyLoad({
                threshold: 400
            });

            /**
             * Reframe
             */
            reframe('.reframe');

            /**
             * Cookie message
             */
            const cookieMessage = document.getElementById('cookie-message');
            const cookieMessageConfirm = document.getElementById('cookie-message-confirm');

            if (cookieMessageConfirm) {
                cookieMessageConfirm.addEventListener('click', () => {
                    cookieMessage.remove();
                });
            }

            /**
             * Carousels
             */
            const carouselEls = document.querySelectorAll('.carousel');
            carouselEls.forEach((el) => {
                let imgsLoad = imagesLoaded(el);

                function onAlways(instance) {
                    new Flickity(el, {
                        cellAlign: 'left',
                        wrapAround: true,
                        pageDots: false,
                        arrowShape: 'M51.6 100 .3 49.9 51.5 0 58 6.7 18.4 45.3h81.9v9.3H18.4l39.8 38.7z'
                    });
                }

                imgsLoad.on('always', onAlways);
            });
        });

    })();

class Forms {
    constructor() {
        // Find the corresponding Form
        let freeformsEls = document.querySelectorAll('form[data-freeform]');

        freeformsEls.forEach((form) => {
            var formHandle = form.getAttribute('data-handle');

            // Styling for AJAX responses
            form.addEventListener("freeform-ready", ()=> {
                var freeform = event.freeform;

                freeform.setOption("errorClassBanner", ["bg-black", "text-white", "px-4", "py-3", "rounded", "relative", "mb-4"]);
                freeform.setOption("errorClassList", ["errors", "text-red-500", "text-base"]);
                freeform.setOption("errorClassField", ["border-red-500"]);
                freeform.setOption("successClassBanner", ["bg-black", "text-white", "px-4", "py-3", "rounded", "relative", "mb-4"]);

                // Get new session details for the form
                // This is good for other sessions for different users
                this._reset(form, formHandle);
            });

            // Styling for Stripe Payments field
            form.addEventListener("freeform-stripe-appearance", (event) => {
                event.elementOptions.appearance = Object.assign(
                    event.elementOptions.appearance,
                    {
                        variables: {
                            colorPrimary: "#0d6efd",
                            fontFamily: "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"",
                            fontSizeBase: "16px",
                            spacingUnit: "0.2em",
                            tabSpacing: "10px",
                            gridColumnSpacing: "20px",
                            gridRowSpacing: "20px",
                            colorText: "#212529",
                            colorBackground: "#ffffff",
                            colorDanger: "rgb(239 68 68)",
                            borderRadius: "5px",
                        },
                        rules: {
                            '.Tab, .Input': {
                                border: '1px solid rgb(148 163 184)',
                                boxShadow: 'none',
                            },
                            '.Tab:focus, .Input:focus': {
                                border: '1px solid #0b5ed7',
                                boxShadow: 'none',
                                outline: '0',
                                transition: 'border-color .15s ease-in-out',
                            },
                            '.Label': {
                                fontSize: '16px',
                                fontWeight: '500',
                            },
                        },
                    }
                );
            });

            form.addEventListener('freeform-render-success',  (event) => {
                // Reset form after submission
                this._reset(form, formHandle);
            });
        });
    }
    _reset(form, formHandle) {
        // Reset form fields for AJAX resubmission
        fetch('/dynamic-form?form='+formHandle, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(json => {
                // Update the Form Hash
                form.querySelector('input[name=formHash]').value = json.hash;

                // Locate and update the CSRF input
                let csrf = json.csrf;
                form.querySelector('input[name=' + csrf.name + ']').value = csrf.value;
            })
            .catch(error => {
                console.error(error);
            });
    }
}

export default new Forms();

// Imports
import { gsap } from 'gsap';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';


const ANIMATION_DURATION = .45;
const ANIMATION_EASE = 'expo.out';
const ACTIVE_CLASS = '_active';
const OVERLAY_CLASS = '_overlay-open';

const HTML_CLASSLIST = document.documentElement.classList;

class NavActions {
    constructor() {
        this.attrName = 'data-nav-action';
        this.openNav = null;
        this.animating = false;

        // Appear elements
        const navActionElements = document.querySelectorAll('[' + this.attrName + ']');

        navActionElements.forEach(el => {
            el.addEventListener('click', element => {
                this.toggle(el);
            });
        });

        // Keydown event
        window.addEventListener('keydown', (e) => {
            // ESC key
            if ((e.key === "Escape" || e.key === "Esc") && this.openNav) {
                this.close(this.openNav);
            }
        });
    }
    open(action) {
        this.openNav = action;
        this.animating = true;

        // Add class to body
        HTML_CLASSLIST.add(OVERLAY_CLASS);
        HTML_CLASSLIST.add('_nav-action-'+action);

        // Update button
        document.querySelector('.btn-action.' + action + '-btn').classList.add(ACTIVE_CLASS);

        // Lock scrolling
        PageScrolling.lock(document.querySelector('.overlay-'+action));

        // Animate nav
        gsap.to('.overlay-' + action, {
            duration: window.SYSTEM.env.reducedMotion ? 0 : ANIMATION_DURATION,
            ease: ANIMATION_EASE,
            x: 0,
            onComplete: () => {
                // Nav button animation
                if (action === 'nav') {
                    gsap.to('.overlay-nav-btn-parent', {
                        duration: window.SYSTEM.env.reducedMotion ? 0 : .3,
                        stagger: window.SYSTEM.env.reducedMotion ? 0 : .08,
                        alpha: 1,
                        y: 0,
                        onComplete: () => {
                            gsap.to('.overlay-nav-btn-social', {
                                duration: window.SYSTEM.env.reducedMotion ? 0 : .3,
                                alpha: 1,
                            });

                            this.animating = false;
                        }
                    });
                }

                // Search animation
                if (action === 'search') {
                    gsap.to('.search-content', {
                        duration: window.SYSTEM.env.reducedMotion ? 0 : .3,
                        alpha: 1,
                        onComplete: () => {
                            this.animating = false;
                        }
                    });
                }
            }
        });

        // Animate overlay header
        gsap.to('.overlay-' + action + ' .overlay-header', {
            duration: window.SYSTEM.env.reducedMotion ? 0 : .2,
            delay: window.SYSTEM.env.reducedMotion ? 0 : (ANIMATION_DURATION - .05),
            alpha: 1
        });

        // Animate nav buttons
        gsap.to('.mn-nav .nav-btns', {
            duration: window.SYSTEM.env.reducedMotion ? 0 : .4,
            y: '-100px'
        });
    }
    close(action, callback) {
        this.animating = true;

        // Update button
        let activeActionBtn = document.querySelector('.btn-action.' + ACTIVE_CLASS);
        if (activeActionBtn) {
            activeActionBtn.classList.remove(ACTIVE_CLASS);
        }

        gsap.to('.overlay-' + action, {
            duration: window.SYSTEM.env.reducedMotion ? 0 : ANIMATION_DURATION,
            ease: ANIMATION_EASE,
            x: '-100%',
            onComplete: () => {
                // Unlock scrolling
                PageScrolling.unlock(document.querySelector('.overlay-'+action));

                this.openNav = null;
                this.animating = false;

                // Callback
                if (typeof callback === 'function') {
                    callback();
                }
            }
        });

        setTimeout(() => {
            // Add class to body
            HTML_CLASSLIST.remove(OVERLAY_CLASS);
            HTML_CLASSLIST.remove('_nav-action-'+action);
        }, ((ANIMATION_DURATION * 1000) - 100));

        // Nav button animation
        if (action === 'nav') {
            gsap.to('.overlay-nav-btn-parent', {
                duration: 0,
                alpha: 0,
                y: '20px'
            });
            gsap.to('.overlay-nav-btn-social', {
                duration: 0,
                alpha: 0,
            });
        }

        // Search animation
        if (action === 'search') {
            gsap.to('.search-content', {
                duration: 0,
                alpha: 0
            });
        }

        // Animate overlay header
        gsap.to('.overlay-' + action + ' .overlay-header', {
            duration: window.SYSTEM.env.reducedMotion ? 0 : .2,
            alpha: 0
        });

        // Animate nav buttons
        gsap.to('.mn-nav .nav-btns', {
            duration: window.SYSTEM.env.reducedMotion ? 0 : .4,
            y: 0
        });
    }
    toggle(el) {
        // Don't do anything whilst animating
        if (this.animating) {
            return false;
        }

        // Get the element action
        let action = el.getAttribute(this.attrName);

        // Check for current open nav
        if (this.openNav) {
            let oldNav = this.openNav;

            // Close open nav
            this.close(this.openNav, () => {
                if (oldNav !== action) {
                    // Open nav
                    this.open(action);
                }
            });
        }
        else {
            if (this.openNav !== action) {
                this.open(action);
            }
        }
    }
}

export default new NavActions();
